.client-song-tile-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
}

.client-song-tile {
	min-height: 80px;
	box-sizing: border-box;
	justify-content: flex-start;
	line-height: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.client-song-text {
	font-family: Museo Sans;
	font-style: normal;
	color: #ffffff;
}

.client-song-info {
	display: flex;
	flex-direction: column;
	max-width: calc(100vw - 200px);
	margin: 8px 0;
}

.client-song-title {
	text-overflow: ellipsis;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 7px;
}

.client-song-artist {
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

.client-song-album-thumbnail {
	height: 60px;
	width: 60px;
	margin-right: 12px;
}

.client-votes-container {
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	margin-left: 2.75px;
	margin-right: 12px;
}

.client-votes-icon {
	line-height: 0;
	margin-bottom: 7.5px;
}

.client-votes-upvote {
	color: #29de71;
}

.client-votes-neutral {
	color: #8674f5;
}

.client-votes-downvote {
	color: #e45858;
}

.client-votes-upvote > div > svg,
.client-votes-downvote > div > svg {
	width: 20px;
	height: 15px;
}

.client-votes-neutral > div > svg {
	width: 17px;
	height: 17px;
}

.client-vote-buttons-container {
	line-height: 0;
	box-sizing: border-box;
}

.client-vote-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	border: 1px solid;
	background: transparent;
	outline: none;
	cursor: pointer;
	user-select: none;
}

.client-vote-button > svg {
	width: 14px;
	height: 13px;
}

.client-vote-button:active > svg > path {
	fill: white;
}

.client-vote-button-upvote {
	border-color: #29de71;
	margin-bottom: 4px;
}

.client-vote-button-upvote:active {
	background: #29de71;
}

.client-vote-button-downvote {
	border-color: #e45858;
}

.client-vote-button-downvote:active {
	background: #e45858;
}

.client-song-tile-add-button {
	margin-right: 16px;
	min-width: 79px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 1px solid #8674f5;
	box-sizing: border-box;
	border-radius: 15px;
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #8674f5;
	cursor: pointer;
	user-select: none;
}

.client-song-tile-add-button:active {
	background: #8674f5;
	color: white;
}

@media only screen and (min-width: 769px) {
	.client-song-tile-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.client-song-tile {
		height: 100px;
	}

	.client-song-album-thumbnail {
		height: 80px;
		width: 80px;
		margin-right: 20px;
	}

	.client-votes-container {
		margin-left: 0;
		margin-right: 16px;
	}

	.client-song-title {
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 11px;
	}

	.client-song-artist {
		font-size: 20px;
		line-height: 24px;
	}

	.client-vote-buttons-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 90px;
	}

	.client-vote-button {
		width: 40px;
		height: 40px;
	}

	.client-vote-button-upvote {
		margin-bottom: 0;
	}

	.client-vote-button > svg {
		width: 19px;
		height: 15px;
	}

	.client-song-tile-add-button {
		width: 85px;
		height: 36px;
		font-size: 20px;
		line-height: 24px;
		border-radius: 20px;
	}
}
