.client-sqh-container {
	height: 54px;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
}

.client-sqh-text {
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	margin-top: -3px;
}

.client-sqh-text-bold {
	font-weight: 900;
}

.client-sqh-text-white {
	color: #ffffff;
}

.client-sqh-text-purple {
	color: #8674f5;
}

.client-sqh-clear-search {
	cursor: pointer;
	user-select: none;
}

.client-sqh-clear-search > span {
	margin-left: 4px;
}

.client-sqh-clear-search > svg {
	width: 10px;
	height: 10px;
}

@media only screen and (min-width: 769px) {
	.client-sqh-container {
		height: 46px;
		margin-top: 19px;
	}

	.client-sqh-text {
		font-size: 20px;
		line-height: 24px;
		margin-top: 0;
	}

	.client-sqh-clear-search > span {
		margin-left: 5px;
	}

	.client-sqh-clear-search > svg {
		width: 16px;
		height: 16px;
		margin-bottom: -2px;
	}
}
