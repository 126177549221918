.search-input-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 40px;
	border: 1px solid #646464;
	box-sizing: border-box;
	border-radius: 20px;
	line-height: 0;
}

.search-input {
	height: 100%;
	padding: 0;
	border: none;
	margin: 0;
	background: transparent;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #8674f5;
	caret-color: #8674f5;
	padding-left: 8px;
	padding-right: 12px;
	flex-grow: 1;
	outline: none;
}

.search-input::placeholder {
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #9a9a9a;
}

.search-icon {
	margin-left: 12px;
	flex-grow: 0;
}

@media only screen and (min-width: 769px) {
	.search-input-container {
		height: 46px;
	}
	.search-icon {
		margin-left: 17px;
	}
	.search-input {
		padding-left: 10px;
	}
}
