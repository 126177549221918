.record-container {
    display: flex;
    justify-content: center;
}

:root {
    --pos-1-x: -120%;
    --pos-1-scale: 0.61;
    --pos-0-x: var(--pos-1-x);
    --pos-0-scale: var(--pos-1-scale);
    --pos-2-x: calc(var(--pos-1-x) / 1.8);
    --pos-2-scale: 0.86;
    --pos-3-x: 0;
    --pos-3-scale: 100%;
    --pos-4-x: calc(var(--pos-2-x) * -1);
    --pos-4-scale: var(--pos-2-scale);
    --pos-5-x: calc(var(--pos-1-x) * -1);
    --pos-5-scale: var(--pos-1-scale);
    --pos-6-scale: var(--pos-5-scale);
    --pos-6-x: var(--pos-5-x);
}

svg.record {
    position: absolute;
    transition: all 0.8s ease-in-out;
}

svg.record.pos-0 {
    transition: none;
    z-index: 0;
    transform: translateX(var(--pos-0-x)) scale(var(--pos-0-scale));
}

svg.record.pos-1 {
    z-index: 1;
    transform: translateX(var(--pos-1-x)) scale(var(--pos-1-scale));
}

svg.record.pos-2 {
    z-index: 2;
    transform: translateX(var(--pos-2-x)) scale(var(--pos-2-scale));
}

svg.record.pos-3 {
    z-index: 3;
    transform: translateX(var(--pos-3-x)) scale(var(--pos-3-scale));
}

svg.record.pos-4 {
    z-index: 2;
    transform: translateX(var(--pos-4-x)) scale(var(--pos-4-scale));
}

svg.record.pos-5 {
    z-index: 1;
    transform: translateX(var(--pos-5-x)) scale(var(--pos-5-scale));
}

svg.record.pos-6 {
    z-index: 0;
    transform: translateX(var(--pos-6-x)) scale(var(--pos-6-scale));
}