.client-container {
	margin-bottom: 90px;
}

.client-wrapper-top-padding {
	padding: 0 16px;
}

@media only screen and (min-width: 769px) {
	.client-container {
		padding: 0 60px;
	}

	.client-wrapper-top-padding {
		padding: 0;
	}
}
