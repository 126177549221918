.client-divider {
	height: 1px;
	background-color: #646464;
}

.client-list-item-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
