.qrside-panel-container {
    height: calc(100% - 160px);
    width: calc( 100% - 40px);
    padding: 80px 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.qrside-header1 {
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0;
    color: #8674F5;
}

.qrside-header2 {
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0;
}

.qrside-qrcode {
    width: 260px;
    height: 260px;
    background: white;
    margin: auto
}

.qrside-qrtext {
    margin: 16px 0;
}