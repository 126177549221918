.emoji-sender-container {
    opacity: 0.9;
	line-height: 0;
	background: rgba(255, 255, 255, 0.19);
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(20px);
	border-radius: 40px;
	height: max-content;
	position: fixed;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
}

.emoji-sender-container:hover {
	cursor: pointer;
	box-shadow: 0 16px 16px rgba(0, 0, 0, 0.25);
}

.emoji-icon {
	width: 64px;
    transition: all 0.25s ease-in-out;
	padding: 20px 8px;
	justify-content: center;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.emoji-icon.active {
	width: 326px;
}

.emoji-icon > svg {
	min-width: 40px;
	transition: all 0.15s ease-in-out;
}

.emoji-icon > svg:active {
	transform: scale(1.3);
}

@media only screen and (min-width: 769px) {
	.emoji-sender-container {
		bottom: 30px;
	}
}
