.home-container {
	padding: 40px 60px;
	max-width: 1280px;
	margin: auto;
}

.hero-content {
	display: flex;
	padding: 20px 0 30px 0;
}

.hero-description {
	padding: 20px 40px 20px 0;
}

.hero-content > div {
	width: 50%;
}

.qa-content {
	padding: 20px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-btn-container {
	flex-direction: column;
	margin-top: 30px;
}

.hero-btn-container-desktop {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.hero-btn-container-mobile {
	display: none;
}

.hero-btn {
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	box-sizing: border-box;
	height: 46px;
	border-radius: 23px;
	outline: none;
	cursor: pointer;
	user-select: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	flex-basis: 2px;
}

.hero-btn-start-party {
	color: white;
	background: #8674f5;
	margin-right: 16px;
}

.hero-btn-join-party {
	color: #8674f5;
	background: transparent;
	border: 1px solid #8674f5;
}

@media screen and (max-width: 768px) {
	.home-container {
		padding: 20px;
	}

	.hero-content {
		flex-direction: column;
	}

	.hero-content > div {
		width: 100%;
	}

	.hero-description {
		padding: 20px 0;
	}

	.qa-content {
		padding: 0;
	}

	.hero-btn-container-desktop {
		display: none;
	}

	.hero-btn-container-mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.hero-btn {
		width: 288px;
		flex-basis: unset;
	}

	.hero-btn-start-party {
		margin-right: 0;
		margin-bottom: 16px;
	}
}
