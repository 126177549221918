div.emoji-container {
    pointer-events: none;
    z-index: 10;
    position: absolute;
    width: 10%;
    left: 0;
    bottom: -100%;
    height: 100%;
    animation: translate 10s infinite linear;
}

div.emoji {
    font-size: 2em;
    position: absolute;
    animation: upDown 1.5s alternate infinite ease-in-out;
    width: 100%;
}

@keyframes upDown {
    to { transform: translateX(100px);}
}
@keyframes translate {
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}