.currently-playing-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.currently-playing-song {
    margin: 0;
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
}

.currently-playing-artist {
    margin: 7px 0 0 0;
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
}

.currently-playing-progress-container {
    margin-top: 42px;
    width: 630px;
    height: 11px;
    background: #413976;
}

.currently-playing-timing-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 630px;
}

.currently-playing-time {
    margin: 5px 2px;
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
}

.currently-playing-icon-container {
    width: 20px;
}

.currently-playing-votes {
    display: flex;
    flex-direction: row;
}

.currently-playing-vote-container {
    display: flex;
    flex-direction: row;
    width: 174px;
    justify-content: space-between;
    margin-top: 24px;
}

.currently-playing-count {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
    text-align: right;
    margin: 0 5px;
}

.currently-playing-count.upvote {
    color: #29DE71;
}

.currently-playing-count.downvote {
    color: #E45858;
}

.currently-playing-progress {
    transition: all 0.1s;
    background: #8674F5;
    height: 100%;
    transform-origin: left;
}
