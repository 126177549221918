.emoji-emitter-container {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.inner-relative-emoji-wrapper {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
}