.footer-container {
    text-align: center;
    margin-top: 40px;
    font-size: 18px;
}
@media screen and (max-width: 768px) {
    .footer-container {
        margin-top: 20px;
        font-size: 16px;
    }
}