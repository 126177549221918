@import url(https://use.typekit.net/lof6vlo.css);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1D1331;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,html {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  font-family: 'museo-sans', sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

h1 {
  font-size: 48px;
  line-height: 64px;
  margin: 8px 0;
}

h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
  margin:0;
}

p {
  margin: 0;
}

a {
  color: #8674F5;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.text-small {
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 18px;
    line-height: 24px;
  }
  
  h1 {
    font-size: 36px;
    line-height: 48px;
  }
  
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  
  p {
    opacity: 0.7;
    margin: 0;
  }
}

/* COLOR */

.text-white {
  color: white;
}

.text-purple {
  color: #8674F5;
}
.App,.App > div {
  height: 100%;
}

h1.header {
    text-align: center;
}

.carousel-container {
    width: 100%;
    margin-top: 80px;
}
.record-container {
    display: flex;
    justify-content: center;
}

:root {
    --pos-1-x: -120%;
    --pos-1-scale: 0.61;
    --pos-0-x: var(--pos-1-x);
    --pos-0-scale: var(--pos-1-scale);
    --pos-2-x: calc(var(--pos-1-x) / 1.8);
    --pos-2-scale: 0.86;
    --pos-3-x: 0;
    --pos-3-scale: 100%;
    --pos-4-x: calc(var(--pos-2-x) * -1);
    --pos-4-scale: var(--pos-2-scale);
    --pos-5-x: calc(var(--pos-1-x) * -1);
    --pos-5-scale: var(--pos-1-scale);
    --pos-6-scale: var(--pos-5-scale);
    --pos-6-x: var(--pos-5-x);
}

svg.record {
    position: absolute;
    -webkit-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out;
}

svg.record.pos-0 {
    -webkit-transition: none;
    transition: none;
    z-index: 0;
    -webkit-transform: translateX(-120%) scale(0.61);
            transform: translateX(-120%) scale(0.61);
    -webkit-transform: translateX(var(--pos-0-x)) scale(var(--pos-0-scale));
            transform: translateX(var(--pos-0-x)) scale(var(--pos-0-scale));
}

svg.record.pos-1 {
    z-index: 1;
    -webkit-transform: translateX(-120%) scale(0.61);
            transform: translateX(-120%) scale(0.61);
    -webkit-transform: translateX(var(--pos-1-x)) scale(var(--pos-1-scale));
            transform: translateX(var(--pos-1-x)) scale(var(--pos-1-scale));
}

svg.record.pos-2 {
    z-index: 2;
    -webkit-transform: translateX(calc(-120% / 1.8)) scale(0.86);
            transform: translateX(calc(-120% / 1.8)) scale(0.86);
    -webkit-transform: translateX(var(--pos-2-x)) scale(var(--pos-2-scale));
            transform: translateX(var(--pos-2-x)) scale(var(--pos-2-scale));
}

svg.record.pos-3 {
    z-index: 3;
    -webkit-transform: translateX(0) scale(100%);
            transform: translateX(0) scale(100%);
    -webkit-transform: translateX(var(--pos-3-x)) scale(var(--pos-3-scale));
            transform: translateX(var(--pos-3-x)) scale(var(--pos-3-scale));
}

svg.record.pos-4 {
    z-index: 2;
    -webkit-transform: translateX(calc(calc(-120% / 1.8) * -1)) scale(0.86);
            transform: translateX(calc(calc(-120% / 1.8) * -1)) scale(0.86);
    -webkit-transform: translateX(var(--pos-4-x)) scale(var(--pos-4-scale));
            transform: translateX(var(--pos-4-x)) scale(var(--pos-4-scale));
}

svg.record.pos-5 {
    z-index: 1;
    -webkit-transform: translateX(calc(-120% * -1)) scale(0.61);
            transform: translateX(calc(-120% * -1)) scale(0.61);
    -webkit-transform: translateX(var(--pos-5-x)) scale(var(--pos-5-scale));
            transform: translateX(var(--pos-5-x)) scale(var(--pos-5-scale));
}

svg.record.pos-6 {
    z-index: 0;
    -webkit-transform: translateX(calc(-120% * -1)) scale(0.61);
            transform: translateX(calc(-120% * -1)) scale(0.61);
    -webkit-transform: translateX(var(--pos-6-x)) scale(var(--pos-6-scale));
            transform: translateX(var(--pos-6-x)) scale(var(--pos-6-scale));
}
.currently-playing-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.currently-playing-song {
    margin: 0;
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
}

.currently-playing-artist {
    margin: 7px 0 0 0;
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
}

.currently-playing-progress-container {
    margin-top: 42px;
    width: 630px;
    height: 11px;
    background: #413976;
}

.currently-playing-timing-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 630px;
}

.currently-playing-time {
    margin: 5px 2px;
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
}

.currently-playing-icon-container {
    width: 20px;
}

.currently-playing-votes {
    display: flex;
    flex-direction: row;
}

.currently-playing-vote-container {
    display: flex;
    flex-direction: row;
    width: 174px;
    justify-content: space-between;
    margin-top: 24px;
}

.currently-playing-count {
    font-family: 'museo-sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
    text-align: right;
    margin: 0 5px;
}

.currently-playing-count.upvote {
    color: #29DE71;
}

.currently-playing-count.downvote {
    color: #E45858;
}

.currently-playing-progress {
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    background: #8674F5;
    height: 100%;
    -webkit-transform-origin: left;
            transform-origin: left;
}

.emoji-emitter-container {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.inner-relative-emoji-wrapper {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
}
div.emoji-container {
    pointer-events: none;
    z-index: 10;
    position: absolute;
    width: 10%;
    left: 0;
    bottom: -100%;
    height: 100%;
    -webkit-animation: translate 10s infinite linear;
            animation: translate 10s infinite linear;
}

div.emoji {
    font-size: 2em;
    position: absolute;
    -webkit-animation: upDown 1.5s alternate infinite ease-in-out;
            animation: upDown 1.5s alternate infinite ease-in-out;
    width: 100%;
}

@-webkit-keyframes upDown {
    to { -webkit-transform: translateX(100px); transform: translateX(100px);}
}

@keyframes upDown {
    to { -webkit-transform: translateX(100px); transform: translateX(100px);}
}
@-webkit-keyframes translate {
    to {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
        opacity: 0;
    }
}
@keyframes translate {
    to {
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
        opacity: 0;
    }
}
.qrside-panel-container {
    height: calc(100% - 160px);
    width: calc( 100% - 40px);
    padding: 80px 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.qrside-header1 {
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0;
    color: #8674F5;
}

.qrside-header2 {
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0;
}

.qrside-qrcode {
    width: 260px;
    height: 260px;
    background: white;
    margin: auto
}

.qrside-qrtext {
    margin: 16px 0;
}
.song-queue-container {
	height: 100%;
	width: calc(100% - 1px);
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

/*Only add border top to the first SongTile*/
.song-queue-container > .top-queue-header + .song-tile-container {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.top-queue-header {
	height: 107px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

p.up-next {
	padding-bottom: 21px;
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 25px;
	margin-left: 20px;
}

.song-tile-container {
    width: 100%;
    height: 111px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.verbiage-container {
    flex-grow: 1;
}

.icon-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

p.vote-count {
    margin: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
}

p.tile-song {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    margin: 6px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 320px;
    text-overflow: ellipsis;
}

p.tile-artist {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    margin: 6px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 320px;
    text-overflow: ellipsis;
}

.desktop-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.side-panel {
    width: 400px;
    height: 100%;
}

.center-content {
    flex-grow: 1;
    position: relative;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0.52%, #1D1331), color-stop(49.38%, #432170), color-stop(98.79%, #1D1331));
    background: linear-gradient(0deg, #1D1331 0.52%, #432170 49.38%, #1D1331 98.79%);
}

.center-header-wrapper {
    width: 100%;
    padding-top: 86px;
    display: flex;
    justify-content: center;
}
.demo-images-container {
    width: 100%;
}

.demo-images {
    width: 100%;
}
.qa-entry-container {
    margin: 36px 0;
}
.footer-container {
    text-align: center;
    margin-top: 40px;
    font-size: 18px;
}
@media screen and (max-width: 768px) {
    .footer-container {
        margin-top: 20px;
        font-size: 16px;
    }
}
.home-container {
	padding: 40px 60px;
	max-width: 1280px;
	margin: auto;
}

.hero-content {
	display: flex;
	padding: 20px 0 30px 0;
}

.hero-description {
	padding: 20px 40px 20px 0;
}

.hero-content > div {
	width: 50%;
}

.qa-content {
	padding: 20px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-btn-container {
	flex-direction: column;
	margin-top: 30px;
}

.hero-btn-container-desktop {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.hero-btn-container-mobile {
	display: none;
}

.hero-btn {
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	box-sizing: border-box;
	height: 46px;
	border-radius: 23px;
	outline: none;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	flex-basis: 2px;
}

.hero-btn-start-party {
	color: white;
	background: #8674f5;
	margin-right: 16px;
}

.hero-btn-join-party {
	color: #8674f5;
	background: transparent;
	border: 1px solid #8674f5;
}

@media screen and (max-width: 768px) {
	.home-container {
		padding: 20px;
	}

	.hero-content {
		flex-direction: column;
	}

	.hero-content > div {
		width: 100%;
	}

	.hero-description {
		padding: 20px 0;
	}

	.qa-content {
		padding: 0;
	}

	.hero-btn-container-desktop {
		display: none;
	}

	.hero-btn-container-mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.hero-btn {
		width: 288px;
		flex-basis: unset;
	}

	.hero-btn-start-party {
		margin-right: 0;
		margin-bottom: 16px;
	}
}

.client-header {
	height: 52px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	line-height: 0;
}

.client-header > svg {
	width: 141.5px;
	height: 20px;
}

.client-header-room-container {
	display: none;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.client-header-room-text {
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 36px;
	color: #646464;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.client-header-room-text-bold {
	font-weight: bold;
}

.client-header-room-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 1px solid #8674f5;
	box-sizing: border-box;
	border-radius: 23px;
	height: 46px;
	width: 160px;
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #8674f5;
	margin-left: 11px;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

@media only screen and (min-width: 769px) {
	.client-header {
		height: 120px;
		margin-top: 10px;
		justify-content: space-between;
	}

	.client-header > svg {
		width: 283px;
		height: 40px;
	}

	.client-header-room-container {
		display: flex;
	}
}

.search-input-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: 40px;
	border: 1px solid #646464;
	box-sizing: border-box;
	border-radius: 20px;
	line-height: 0;
}

.search-input {
	height: 100%;
	padding: 0;
	border: none;
	margin: 0;
	background: transparent;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #8674f5;
	caret-color: #8674f5;
	padding-left: 8px;
	padding-right: 12px;
	flex-grow: 1;
	outline: none;
}

.search-input::-webkit-input-placeholder {
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #9a9a9a;
}

.search-input:-ms-input-placeholder {
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #9a9a9a;
}

.search-input::-ms-input-placeholder {
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #9a9a9a;
}

.search-input::placeholder {
	font-family: museo-sans;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.165px;
	color: #9a9a9a;
}

.search-icon {
	margin-left: 12px;
	flex-grow: 0;
}

@media only screen and (min-width: 769px) {
	.search-input-container {
		height: 46px;
	}
	.search-icon {
		margin-left: 17px;
	}
	.search-input {
		padding-left: 10px;
	}
}

.client-sqh-container {
	height: 54px;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
}

.client-sqh-text {
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	margin-top: -3px;
}

.client-sqh-text-bold {
	font-weight: 900;
}

.client-sqh-text-white {
	color: #ffffff;
}

.client-sqh-text-purple {
	color: #8674f5;
}

.client-sqh-clear-search {
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.client-sqh-clear-search > span {
	margin-left: 4px;
}

.client-sqh-clear-search > svg {
	width: 10px;
	height: 10px;
}

@media only screen and (min-width: 769px) {
	.client-sqh-container {
		height: 46px;
		margin-top: 19px;
	}

	.client-sqh-text {
		font-size: 20px;
		line-height: 24px;
		margin-top: 0;
	}

	.client-sqh-clear-search > span {
		margin-left: 5px;
	}

	.client-sqh-clear-search > svg {
		width: 16px;
		height: 16px;
		margin-bottom: -2px;
	}
}

.client-song-tile-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
}

.client-song-tile {
	min-height: 80px;
	box-sizing: border-box;
	justify-content: flex-start;
	line-height: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.client-song-text {
	font-family: Museo Sans;
	font-style: normal;
	color: #ffffff;
}

.client-song-info {
	display: flex;
	flex-direction: column;
	max-width: calc(100vw - 200px);
	margin: 8px 0;
}

.client-song-title {
	text-overflow: ellipsis;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 7px;
}

.client-song-artist {
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
}

.client-song-album-thumbnail {
	height: 60px;
	width: 60px;
	margin-right: 12px;
}

.client-votes-container {
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	margin-left: 2.75px;
	margin-right: 12px;
}

.client-votes-icon {
	line-height: 0;
	margin-bottom: 7.5px;
}

.client-votes-upvote {
	color: #29de71;
}

.client-votes-neutral {
	color: #8674f5;
}

.client-votes-downvote {
	color: #e45858;
}

.client-votes-upvote > div > svg,
.client-votes-downvote > div > svg {
	width: 20px;
	height: 15px;
}

.client-votes-neutral > div > svg {
	width: 17px;
	height: 17px;
}

.client-vote-buttons-container {
	line-height: 0;
	box-sizing: border-box;
}

.client-vote-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	border: 1px solid;
	background: transparent;
	outline: none;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.client-vote-button > svg {
	width: 14px;
	height: 13px;
}

.client-vote-button:active > svg > path {
	fill: white;
}

.client-vote-button-upvote {
	border-color: #29de71;
	margin-bottom: 4px;
}

.client-vote-button-upvote:active {
	background: #29de71;
}

.client-vote-button-downvote {
	border-color: #e45858;
}

.client-vote-button-downvote:active {
	background: #e45858;
}

.client-song-tile-add-button {
	margin-right: 16px;
	min-width: 79px;
	height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 1px solid #8674f5;
	box-sizing: border-box;
	border-radius: 15px;
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #8674f5;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.client-song-tile-add-button:active {
	background: #8674f5;
	color: white;
}

@media only screen and (min-width: 769px) {
	.client-song-tile-wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.client-song-tile {
		height: 100px;
	}

	.client-song-album-thumbnail {
		height: 80px;
		width: 80px;
		margin-right: 20px;
	}

	.client-votes-container {
		margin-left: 0;
		margin-right: 16px;
	}

	.client-song-title {
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 11px;
	}

	.client-song-artist {
		font-size: 20px;
		line-height: 24px;
	}

	.client-vote-buttons-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 90px;
	}

	.client-vote-button {
		width: 40px;
		height: 40px;
	}

	.client-vote-button-upvote {
		margin-bottom: 0;
	}

	.client-vote-button > svg {
		width: 19px;
		height: 15px;
	}

	.client-song-tile-add-button {
		width: 85px;
		height: 36px;
		font-size: 20px;
		line-height: 24px;
		border-radius: 20px;
	}
}

.client-divider {
	height: 1px;
	background-color: #646464;
}

.client-list-item-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.emoji-sender-container {
    opacity: 0.9;
	line-height: 0;
	background: rgba(255, 255, 255, 0.19);
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	-webkit-backdrop-filter: blur(20px);
	        backdrop-filter: blur(20px);
	border-radius: 40px;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	position: fixed;
	bottom: 40px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.emoji-sender-container:hover {
	cursor: pointer;
	box-shadow: 0 16px 16px rgba(0, 0, 0, 0.25);
}

.emoji-icon {
	width: 64px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
	padding: 20px 8px;
	justify-content: center;
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.emoji-icon.active {
	width: 326px;
}

.emoji-icon > svg {
	min-width: 40px;
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.emoji-icon > svg:active {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
}

@media only screen and (min-width: 769px) {
	.emoji-sender-container {
		bottom: 30px;
	}
}

.client-container {
	margin-bottom: 90px;
}

.client-wrapper-top-padding {
	padding: 0 16px;
}

@media only screen and (min-width: 769px) {
	.client-container {
		padding: 0 60px;
	}

	.client-wrapper-top-padding {
		padding: 0;
	}
}

