.client-header {
	height: 52px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	line-height: 0;
}

.client-header > svg {
	width: 141.5px;
	height: 20px;
}

.client-header-room-container {
	display: none;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.client-header-room-text {
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	line-height: 36px;
	color: #646464;
	width: max-content;
}

.client-header-room-text-bold {
	font-weight: bold;
}

.client-header-room-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 1px solid #8674f5;
	box-sizing: border-box;
	border-radius: 23px;
	height: 46px;
	width: 160px;
	font-family: 'museo-sans';
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #8674f5;
	margin-left: 11px;
	cursor: pointer;
	user-select: none;
}

@media only screen and (min-width: 769px) {
	.client-header {
		height: 120px;
		margin-top: 10px;
		justify-content: space-between;
	}

	.client-header > svg {
		width: 283px;
		height: 40px;
	}

	.client-header-room-container {
		display: flex;
	}
}
