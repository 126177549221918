@import url("https://use.typekit.net/lof6vlo.css");

body {
  font-family: 'museo-sans', sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
}

h1 {
  font-size: 48px;
  line-height: 64px;
  margin: 8px 0;
}

h2 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 500;
  margin:0;
}

p {
  margin: 0;
}

a {
  color: #8674F5;
  cursor: pointer;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.text-small {
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 18px;
    line-height: 24px;
  }
  
  h1 {
    font-size: 36px;
    line-height: 48px;
  }
  
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  
  p {
    opacity: 0.7;
    margin: 0;
  }
}

/* COLOR */

.text-white {
  color: white;
}

.text-purple {
  color: #8674F5;
}