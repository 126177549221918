
.desktop-container {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.side-panel {
    width: 400px;
    height: 100%;
}

.center-content {
    flex-grow: 1;
    position: relative;
    background: linear-gradient(0deg, #1D1331 0.52%, #432170 49.38%, #1D1331 98.79%);
}

.center-header-wrapper {
    width: 100%;
    padding-top: 86px;
    display: flex;
    justify-content: center;
}